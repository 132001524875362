<template>
  <div class="pc-wrap">
    <div>
      <img :src="banner" alt="" />
    </div>
    <div class="pc-content">
      <img
        v-for="(item, index) in content"
        :key="index"
        class="con-img"
        :class="{ pointer: item.url }"
        :src="item.img"
        alt=""
        @click="$jump(item.url)"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      banner: "https://img.runzehui.net/2022/01/12/1c967202201121402099147.jpg",
      content: [
        {
          img: "https://img.runzehui.net/2022/01/17/7a314202201171953182071.jpg",
          url: "",
        },
        {
          img: "https://img.runzehui.net/2022/01/17/65cf0202201171958037739.jpg",
          url: "/about/pigeon",
        },
        {
          img: "https://img.runzehui.net/2022/01/17/f33c9202201171958234023.jpg",
          url: "/news/12",
        },
        {
          img: "https://img.runzehui.net/2022/01/17/55ee0202201171958526224.jpg",
          url: "/news/13",
        },
        {
          img: "https://img.runzehui.net/2022/01/17/b3ad9202201171959431496.jpg",
          url: "/news/16",
        },
        {
          img: "https://img.runzehui.net/2022/01/17/5bf4c20220117195955278.jpg",
          url: "/news/14",
        },
      ],
    };
  },
};
</script>

<style scoped>
img {
  display: block;
  width: 100%;
}
.con-img {
  margin: 60px 0;
}
.pointer {
  cursor: pointer;
}
@media (min-width: 320px) and (max-width: 430px) {
  .con-img {
    margin: 20px 0;
  }
}
</style>